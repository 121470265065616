import _baseSortedUniq from "./_baseSortedUniq";
var exports = {};
var baseSortedUniq = _baseSortedUniq;

/**
 * This method is like `_.uniq` except that it's designed and optimized
 * for sorted arrays.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Array
 * @param {Array} array The array to inspect.
 * @returns {Array} Returns the new duplicate free array.
 * @example
 *
 * _.sortedUniq([1, 1, 2]);
 * // => [1, 2]
 */
function sortedUniq(array) {
  return array && array.length ? baseSortedUniq(array) : [];
}
exports = sortedUniq;
export default exports;